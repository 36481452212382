export const LOCATION_SEARCH = '/explore'
export const LOCATION_APPOINTMENT = '/l/:uuid/appointment'
export const LOCATION_CHECKIN = '/l/:uuid/checkin'
export const LOCATION_OVERVIEW = '/l/:uuid'

export const USER_DASHBOARD = '/profile'

export const USER_APPOINTMENTS = '/profile/appointments/:type'
export const APPOINTMENT_OVERVIEW = '/profile/appointment/:id'

export const USER_PAYMENT_METHODS = '/profile/billing'
export const USER_PREFERENCES = '/profile/settings'
export const USER_SETTINGS_EDIT_ACCOUNT = '/profile/settings/edit-account'
export const USER_SETTINGS_CHANGE_PASSWORD = '/profile/settings/change-password'

export const AUTH_REGISTER = '/auth/register'
export const AUTH_LOGIN = '/auth/login'

export const AUTH_FORGOT_PASSWORD = '/auth/forgot-password'
